class StaticLink {
  constructor() {
    this.host = process.env.REACT_APP_BACKEND_HOST || "";
  }

  static links() {
    return new StaticLink();
  }

  get home() {
    return this.host || "/";
  }

  get dashboard() {
    return `${this.host}/dashboard`;
  }

  get pastAuction() {
    return `${this.host}/past-auctions`;
  }

  get create_listings() {
    return `${this.host}/listings/new`;
  }

  get fishery() {
    return `${this.host}/fisheries`;
  }

  get howItWork() {
    return `${this.host}/how_it_works`;
  }

  get privacyPolicy() {
    return `${this.host}/privacy_policy`;
  }

  get termsOfService() {
    return `${this.host}/terms_of_service`;
  }
  get sellQuotaUnits() {
    return `${this.host}/sell_quota_units`;
  }

  get fee() {
    return `${this.host}/fee`;
  }

  get signout() {
    return `${this.host}/sign_out`;
  }

  get sign_in() {
    return `${this.host}/sign_in`;
  }
  get sign_up() {
    return `${this.host}/sign_up`;
  }
  get profile_edit() {
    return `${this.host}/profile/edit`;
  }
  get profile_settings() {
    return `${this.host}/profile/settings`;
  }

  get purchase_order() {
    return `${this.host}/orders?type=purchase`;
  }
  get bids_order() {
    return `${this.host}/bids`;
  }
  get listings_order() {
    return `${this.host}/listings`;
  }
  get sales_order() {
    return `${this.host}/orders?type=sales`;
  }

  auctionShow(id) {
    return `${this.host}/auctions/${id}`;
  }

  get wallet() {
    return `${this.host}/wallet`;
  }

}
const links = StaticLink.links();

export { links };
