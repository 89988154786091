/**
 * Component TimeCountDown
 *
 * @example
 *
 * <TimeCountDown date={Date|String|Number} />. required  Date or timestamp in the future
 * <TimeCountDown date={Date|String|Number} theme="purple|danger" />
 * <TimeCountDown date={Date|String|Number} theme="purple|danger" themeClosed="purple|danger" timeClosed={Number(millisecond)} />
 * <TimeCountDown date={Date|String|Number} inline={true} {...other Countdown props } />
 *
 * base: https://github.com/ndresx/react-countdown
 */
import React, { Component } from "react";
import Countdown from "react-countdown-now";
import moment from "moment";
import classnames from "classnames";
import { isDaysAfter } from "../../utils/date";

export default class TimeCountDown extends Component {
  constructor(props) {
    super(props);
  }
  setRef = (countdown) => {
    if (countdown) {
      this.countdown = countdown;
    }
  };

  handlePauseClick = () => {
    this.countdown && this.countdown.pause();
  };

  handleStartClick = () => {
    this.countdown && this.countdown.start();
  };

  render() {
    return (
      <Countdown
        {...this.props}
        ref={this.setRef}
        renderer={(delta) =>
          rendererCountDown(
            delta,
            this.props.theme,
            this.props.themeClosed,
            this.props.timeClosed,
            this.props.themeComplete,
            this.props.date,
            this.props.showDate,
            this.props.inline,
            this.props.leadingIcon,
            this.props.prefix,
            this.props.prefixLabel
          )
        }
      />
    );
  }
}

const renderCompletionist = (date, themeComplete = "text-blue-gray-500") => (
  <span className={classnames("time-countdown-end", themeComplete)}>
    {moment(date).format("DD MMM YYYY HH:mmA")}
  </span>
);
const renderDateUnit = (date, unit) => {
  return date > 1 ? `${unit}s` : unit;
};
// Renderer callback with condition
const rendererCountDown = (
  { total, days, hours, minutes, seconds, completed },
  theme,
  themeClosed,
  timeClosed,
  themeComplete,
  date,
  showDate,
  inline,
  leadingIcon,
  prefix,
  prefixLabel
) => {
  if (completed) {
    // Render a complete state
    return renderCompletionist(date, themeComplete);
  } else {
    // Render a countdown
    let themeCurrent = classnames(theme, {
      inline: inline,
    });
    if (themeClosed && timeClosed && total <= timeClosed) {
      themeCurrent = themeClosed;
    }

    //判断showDate是否为TRUE，来render不同的时期格式

    if (days < 1) {
      return (
        <div className={`time-countdown ${themeCurrent}`}>
          {leadingIcon && <span className="mr-2">{leadingIcon}</span>}
          <span>
            {prefixLabel?prefixLabel:''}
            {prefix && typeof prefix == "function" && prefix(days)}
            {hours} <span>{renderDateUnit(hours, "hour")}</span>, {minutes}{" "}
            <span>{renderDateUnit(minutes, "min")}</span>, {seconds}{" "}
            <span>{renderDateUnit(seconds, "sec")}</span>
          </span>
        </div>
      );
    }
    if (showDate) {
      if (isDaysAfter(date, 2)) {
        return (
          <div className={`time-countdown ${themeCurrent}`}>
            {leadingIcon && <span className="mr-2">{leadingIcon}</span>}
            <span>
              {prefixLabel?prefixLabel:''}
              {prefix && typeof prefix == "function" && prefix(days)}
              {moment(date).format("DD MMM YYYY HH:mmA")}
            </span>
          </div>
        );
      }
      if (isDaysAfter(date, 1)) {
        return (
          <div className={`time-countdown ${themeCurrent}`}>
            {leadingIcon && <span className="mr-2">{leadingIcon}</span>}
            <span>
              {prefixLabel?prefixLabel:''}
              {prefix && typeof prefix == "function" && prefix(days)}
              {moment(date).format("[Tomorrow at] h:mma")}
            </span>
          </div>
        );
      }
    }
    return (
      <div className={`time-countdown ${themeCurrent}`}>
        {leadingIcon && <span className="mr-2">{leadingIcon}</span>}
        <span>
          {prefixLabel?prefixLabel:''}
          {prefix && typeof prefix == "function" && prefix(days)}
          {days} <span>{renderDateUnit(days, "day")}</span>, {hours}{" "}
          <span>{renderDateUnit(hours, "hour")}</span>, {minutes}{" "}
          <span>{renderDateUnit(minutes, "min")}</span>
        </span>
      </div>
    );
  }
};
