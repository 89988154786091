import React, { useState } from "react";
import PropTypes from "prop-types";
import get from "lodash/get";
import map from "lodash/map";
import isEmpty from "lodash/isEmpty";
import cx from "classnames";
import { Switch } from '@headlessui/react'


import { Link, Loading, TimeCountDown } from "@components";

const NotificatList = ({ notificationList, notificationLoading, listLoading, data, onSubmit, followLoading }) => {

  const [currentFollowId, setCurrenFollowId] = useState(null);
  const [tabChange, setTabChange] = useState(false);

  const renderTimeCreate = (time) =>{
    let createTime = Date.parse(time);
    let nowTime = window.moment.now();
    let resultTime = nowTime - createTime;
    if((resultTime / 1000 / 3600 / 24) > 1){
      return `${Math.floor(resultTime / 1000 / 3600 / 24)} Days ago · `
    } else if((resultTime / 1000 / 3600 / 24) == 1){
      return '1 Day ago · '
    } else if((resultTime / 1000 / 3600) > 1){
      return `${Math.floor(resultTime / 1000 / 3600)} Hours ago · `
    } else if((resultTime / 1000 / 3600) == 1){
      return '1 Hour ago · '
    } else if((resultTime / 1000 / 60) > 1){
      return `${Math.floor(resultTime / 1000 / 60)} Mins ago · `
    } else if((resultTime / 1000 / 60) == 1){
      return '1 Min ago · '
    }
  }

  return (
    <div className="">
      <div className="flex justify-between items-center py-4 px-6 border-b border-blue-gray-200">
        <h2 className="text-base text-gray-900 font-medium tracking-cus-sm" style={{lineHeight:'19px'}}>
          Notifications and watchlist
        </h2>
      </div>
      <div className="p-4 pt-2">
        <ul className="bg-cus-gray-light rounded-lg grid grid-cols-2" style={{padding:'2px',gridColumnGap: '2px'}}>
          <li className={cx( tabChange? 'text-gray-600 hover:bg-light' : 'bg-white text-dark font-medium', 'rounded-lg py-1 text-center text-sm leading-cus-xl tracking-cus-sm cursor-pointer transition duration-300 ease-linear' )} 
            onClick={() => {
              setTabChange(false);
            }}>Notifications</li>
          <li className={cx( tabChange? 'bg-white text-dark font-medium' : 'text-gray-600 hover:bg-light', 'rounded-lg py-1 text-center text-sm leading-cus-xl tracking-cus-sm cursor-pointer transition duration-300 ease-linear' )}
            onClick={() => {
              setTabChange(true);
            }}>Watchlist</li>
        </ul>
        {
          !tabChange ? (
            <div className="">
              { notificationLoading ? (
                <div>
                  <Loading />
                </div>
              ): (
                <ul className="mt-4 wacthlist-input watchlist-height divide-y divide-gray-100">
                  {
                    (isEmpty(notificationList) && (
                      <li className="rounded-lg py-6 text-center text-gray-900">No data</li>
                    ))
                  }
                  {map(notificationList, (item, index) => {
                    return (
                      <li
                        key={index}
                        className=""
                      >
                        <Link to={item.url} className="block py-5">
                          <div className="text-sm">
                            <div className="flex items-center space-x-2">
                              <div className="text-dark text-sm tracking-cus-sm" style={{lineHeight: '14px'}}>{item.no} · </div>
                              <div className="text-gray-500 text-sm tracking-cus-sm leading-none">{renderTimeCreate(item.created_at)}</div>
                            </div>
                            <div className="mt-2 text-sm text-gray-900 font-medium tracking-cus-sm" style={{lineHeight: '14px'}}>{item.message}</div>
                            <div className="mt-2">
                              <div className="flex-1 truncate text-gray-500 text-xxs tracking-cus-sm" style={{lineHeight: '14px'}}>{item.title}</div>
                            </div>
                          </div>
                        </Link>
                      </li>
                    );
                  })}
                </ul>
              )}
            </div>
          ) : (
            <div className="">
              { listLoading ? (
                <div>
                  <Loading />
                </div>
              ): (
                <ul className="mt-4 wacthlist-input watchlist-height">
                  {
                    (isEmpty(data) && (
                      <li className="rounded-lg py-6 text-center text-gray-900">No data</li>
                    ))
                  }
                  {map(data, (item, index) => {
                    return (
                      <li
                        key={index}
                        className="mb-3 md:mb-4"
                      >
                        <div className="flex justify-between items-center">
                          <p className="flex-1 mr-4 text-sm text-blue-gray-500">{item.title}</p>
                          {
                            followLoading && currentFollowId === item.id ? (
                              <Loading size="sm" spacing="p-0" />
                            ):(
                              <Switch
                                checked={item.following}
                                onChange={() => {
                                  if (item.id) {
                                    onSubmit({ id: item.id, following: item.following });
                                    setCurrenFollowId(item.id);
                                  }
                                }}
                                className={cx(
                                  item.following ? 'bg-fish-primary' : 'bg-gray-100',
                                  'relative inline-flex flex-shrink-0 h-6 switch-w-44 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500'
                                )}
                              >
                                <span
                                  className={cx(
                                    item.following ? 'translate-x-5' : 'translate-x-0',
                                    'pointer-events-none relative inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200'
                                  )}
                                >
                                  <span
                                    className={cx(
                                      item.following ? 'opacity-0 ease-out duration-100' : 'opacity-100 ease-in duration-200',
                                      'absolute inset-0 h-full w-full flex items-center justify-center transition-opacity'
                                    )}
                                    aria-hidden="true"
                                  >
                                    <svg className="bg-white h-3 w-3 text-blue-gray-400" fill="none" viewBox="0 0 12 12">
                                      <path
                                        d="M4 8l2-2m0 0l2-2M6 6L4 4m2 2l2 2"
                                        stroke="currentColor"
                                        strokeWidth={2}
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                      />
                                    </svg>
                                  </span>
                                  <span
                                    className={cx(
                                      item.following ? 'opacity-100 ease-in duration-200' : 'opacity-0 ease-out duration-100',
                                      'absolute inset-0 h-full w-full flex items-center justify-center transition-opacity'
                                    )}
                                    aria-hidden="true"
                                  >
                                    <svg className="bg-white h-3 w-3 text-fish-primary" fill="currentColor" viewBox="0 0 12 12">
                                      <path d="M3.707 5.293a1 1 0 00-1.414 1.414l1.414-1.414zM5 8l-.707.707a1 1 0 001.414 0L5 8zm4.707-3.293a1 1 0 00-1.414-1.414l1.414 1.414zm-7.414 2l2 2 1.414-1.414-2-2-1.414 1.414zm3.414 2l4-4-1.414-1.414-4 4 1.414 1.414z" />
                                    </svg>
                                  </span>
                                </span>
                              </Switch>
                            )
                          }
                        </div>
                      </li>
                    );
                  })}
                </ul>
              )}
            </div>
          )
        }
      </div>

    </div>
  );
};

NotificatList.propTypes = {
};

NotificatList.defaultProps = {};

export default NotificatList;